import {useEffect, useState} from "react";
import "./Tile.css";

const tileToClass = {
  GRASS: "grass",
  DESERT: "desert",
  CITY: "city",
  POI: "poi"
};

function TilePlacement({config, me, position, alpha}) {
  const [translate_x, setTranslate_x] = useState(null);
  const [translate_y, setTranslate_y] = useState(null);
  const [squareSize, setSquareSize] = useState(1);
  const [size, setSize] = useState();

  useEffect(() => {
    const {vision} = config;
    setSquareSize(size / (vision * 2 + 1));
  }, [me, config, size]);

  useEffect(() => {
    setSize(Math.max(config.size.width, config.size.height));
  }, [config]);

  useEffect(() => {
    const {
      coord: {x, y},
    } = me;
    setTranslate_x((x - position.x) * squareSize);
    setTranslate_y((position.y - y) * squareSize);
  }, [position, me, squareSize]);

  return (
      <>
        {translate_x !== null & alpha > 0 && (
            <g>
              <g className={tileToClass[me.type]} opacity={alpha}
                 transform={`translate(${translate_x} ${translate_y})`}
                 meta={JSON.stringify(me.coord)}>
                <use href={`#Tile_${tileToClass[me.type]}`}/>
              </g>
            </g>
        )}
      </>
  );
}

export default TilePlacement;
