import "./Seed.css";

function Seed({config, seed}) {
  const width = 80
  const height = 25
  const start = {x: 20 - width, y: -20}
  const {x, y} = start
  const end = {x: x + width, y: y + height}
  const {x: x_end, y: y_end} = end
  const a = {x: x + 1, y: y_end - 6}
  const c1 = {x: x - 5, y: y_end}
  const c2 = {x: x_end + 5, y: y_end}
  const b = {x: x_end - 1, y: y_end - 6}
  const size = Math.max(config.size.width, config.size.height)

  return (
      <>
        seed && (
        <g transform={` translate(${size - 50} 40)`}
           className={'Seed'} textAnchor="middle"
           style={{filter: "url(#dropshadow)"}}>

          <g opacity={0.4}>
            <rect x={x} y={y} height={height} width={width}/>
            <g opacity={0.4}>
              <path
                  d={`M${a.x},${a.y} C${c1.x},${c1.y} ${c2.x},${c2.y} ${b.x},${b.y}`}/>
              <line x1={x + 1} y1={3} x2={x_end - 1} y2={3}/>
            </g>
            <circle cx={a.x} cy={a.y} r="1"/>
            <circle cx={b.x} cy={b.y} r="1"/>
            <circle cx={c1.x} cy={c1.y} r="1"/>
            <circle cx={c2.x} cy={c2.y} r="1"/>

          </g>
          <rect x={x} y={y} height={height} width={width} className={'border'}/>
          <g transform={` translate(-${((size - 50) / 2)} -${(config.size.width
              / 4) + 8}) scale(0.5)`}>
            <use href={`#Tile_grass`}/>
          </g>
          <text textAnchor={'center'}
                transform={`translate(-16 -2)`}>{`${seed.current
          }/${seed.total
          }`}
          </text>

        </g>)
      </>

  );
}

export default Seed;
