import "./App.css";
import AuthProvider from "./auth/AuthProvider";
import {Route, Routes} from "react-router-dom";
import LoginPage from "./auth/LoginPage";
import RequireAuth from "./auth/RequireAuth";
import AuthStatus from "./auth/AuthStatus";
import WelcomePage from "./Welcome";
import WorldContainer from "./Container";
import Container from '@mui/material/Container';
import VisitContainer from "./visit/Container";

function App() {
  return (
      <div className="App">
        <Container maxWidth="md">
          <AuthProvider>
            <Routes>
              <Route>
                <Route path="/" element={<AuthStatus/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/welcome" element={<WelcomePage/>}/>
                <Route
                    path="/world"
                    element={
                      <RequireAuth>
                        <WorldContainer/>
                      </RequireAuth>
                    }
                />
                <Route
                    path="/visit/:id"
                    element={
                      <RequireAuth>
                        <VisitContainer/>
                      </RequireAuth>
                    }
                />
              </Route>
            </Routes>
          </AuthProvider>
        </Container>
      </div>
  );
}

export default App;
