import { useEffect, useState } from "react";
import "./Me.css";

function Me({ config }) {
  const [x, setX] = useState();
  const [y, setY] = useState();

  useEffect(() => {
    const { size } = config;
    const center_x = size.width / 2;
    const center_y = size.height / 2;
    setX(center_x);
    setY(center_y);
  }, [config]);

  return (
    <g className={"me"} transform="translate(0 5)">
      <circle cx={x} cy={y} r={config.me.radius} />
    </g>
  );
}

export default Me;
