import React, { useState } from "react";
import { googleAuthProvider } from "./GoogleAuthProvider";
import { AuthContext } from "./UseAuth";

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  let signin = async () => {
    try {
      const newUser = await googleAuthProvider.signin()
      setUser(newUser);
    } catch {
      setUser({});
      console.log('oups')
    }
  };

  let signout = () => {
    return googleAuthProvider.signout(() => {
      setUser(null);
    });
  };

  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
