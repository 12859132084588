import {useEffect, useState} from "react";
import Info from "./info/info";
import World from "./svg/World";
import {useAuth} from "./auth/UseAuth";
import './Container.css'

const API_URL = process.env.REACT_APP_API_URL

const config = {
  size: {width:350, height:350}, vision: 8, me: {
    radius: 6,
  },
};

function WorldContainer() {
  const auth = useAuth();
  const [position, setPosition] = useState({});
  const [move, setMove] = useState();
  const [land, setLand] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [activity, setActivity] = useState();
  const [activities, setActivities] = useState([]);

  const computeAlpha = (tile, position) => {
    const {
      coord: {x, y},
    } = tile;
    const show = tile.visible || tile.type === "CITY" || (x === position.x && y === position.y);
    return show ? 1 : 1 - (0.4 + (Math.abs(x - position.x) + Math.abs(
        y - position.y)) / 6);
  };

  useEffect(() => {
    async function fetchMove() {
      setFetching(true)
      const requestOptions = {
        method: 'POST',
        credentials: "include",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({direction: move.direction})
      };
      const response = await fetch(`${API_URL}/move`, requestOptions)
      const newPosition = await response.json()
      setPosition(newPosition)
    }

    move && fetchMove().then()
  }, [move])

  useEffect(() => {
    position.activity && setActivity(position.activity)
  }, [position]);

  useEffect(() => {
    async function fetchWorld() {
      const res = await fetch(`${API_URL}/world`, {
        credentials: "include",
      });
      const newLand = await res.json()
      setLand(newLand)
      setFetching(false)
    }

    fetchWorld().then();
  }, [position]);

  useEffect(() => {
    auth && setPosition(auth.user.position)
  }, [auth]);

  useEffect(() => {
    async function fetchActivities() {
      const res = await fetch(`${API_URL}/activity/all`, {
        credentials: "include",
      });
      const activities = await res.json();
      setActivities(activities)
      console.log('activities', activities)
    }

    fetchActivities().then();
  }, [position]);

  return (
      <div className="Container">
        <World config={config} position={position.coord} setMove={setMove}
               land={land}
               fetching={fetching} seed={activity} computeAlpha={computeAlpha}/>
        <Info activities={activities}/>
      </div>
  );
}

export default WorldContainer;
