const API_URL = process.env.REACT_APP_API_URL;

const googleAuthProvider = {
  isAuthenticated: false,
  async signin() {
    const res = await fetch(`${API_URL}/me`, {
      credentials: "include",
    });
    if (res.ok) {
      const result = await res.json();
      googleAuthProvider.isAuthenticated = true;
      return result;
    } else throw new Error("Not Authentified");
  },
  signout(callback) {
    googleAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};

export { googleAuthProvider };
