import "./Info.css";
import Activities from "./Activities";

function Info({activities}) {
  return (
      <div className="Info">
        <Activities activities={activities}/>
      </div>
  );
}

export default Info;
