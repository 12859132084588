import {useEffect} from "react";
import {Navigate} from "react-router-dom";
import {useAuth} from "./UseAuth";

function AuthStatus() {
  let auth = useAuth();

  useEffect(() => {
    auth.user || auth.signin();
  }, [auth]);

  const redirect = (user) =>
      auth.user.id ? auth.user.position ? 'world' : 'welcome' : 'login'

  return (
      auth.user ? <Navigate to={redirect(auth.user)} replace/>
          : <></>
  );
}

export default AuthStatus;
