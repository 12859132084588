import {useEffect, useState} from "react";
import "./Tile.css";

function Tile({config, type}) {
  const [rect_x, setRect_x] = useState();
  const [rect_y, setRect_y] = useState();
  const [center_x, setCenter_x] = useState();
  const [center_y, setCenter_y] = useState();
  const [squareSize, setSquareSize] = useState(1);
  const [size, setSize] = useState();

  useEffect(() => {
    const {vision} = config;
    setSquareSize(size / (vision * 2 + 1));
  }, [config, size]);

  useEffect(() => {
    setSize(Math.max(config.size.width, config.size.height));
  }, [config]);

  useEffect(() => {
    setCenter_x(config.size.width / 2);
    setCenter_y(config.size.height / 2);
  }, [size]);

  useEffect(() => {
    setRect_x(center_x - squareSize / 2);
    setRect_y(center_y - squareSize / 2);
  }, [squareSize, center_x, center_y]);

  return (
      <>
        {rect_x && (
            <g>
              <g id={`Tile_${type}`} className={type}>
                <rect
                    x={rect_x}
                    y={rect_y}
                    width={squareSize}
                    height={squareSize}
                    rx="2"
                />
                <line
                    x1={rect_x + 1}
                    y1={rect_y + squareSize - 2}
                    x2={rect_x + squareSize - 1}
                    y2={rect_y + squareSize - 2}
                />
                <line
                    x1={rect_x + 1}
                    y1={rect_y + squareSize - 3}
                    x2={rect_x + 2}
                    y2={rect_y + squareSize - 3}
                />
                <line
                    x1={rect_x + squareSize - 1}
                    y1={rect_y + squareSize - 3}
                    x2={rect_x + squareSize - 2}
                    y2={rect_y + squareSize - 3}
                />
              </g>

            </g>
        )}
      </>
  );
}

export default Tile;
