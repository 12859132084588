import {useEffect, useState} from "react";
import Info from "../info/info";
import World from "../svg/World";
import {useAuth} from "../auth/UseAuth";
import { useParams } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL

const config = {
  size: {width:350, height:350}, vision: 8, me: {
    radius: 6,
  },
};

function VisitContainer() {
  const auth = useAuth();
  let params = useParams();
  const [position, setPosition] = useState({});
  const [land, setLand] = useState([]);
  const [fetching] = useState(true);
  const [activities, setActivities] = useState([]);

  const computeAlpha = () => {
    return 1;
  };

  useEffect(() => {
    async function fetchWorld() {
      const res = await fetch(`${API_URL}/visit/${params.id}`, {
        credentials: "include",
      });
      const newLand = await res.json()
      setLand(newLand)
    }
    fetchWorld().then();
  }, [position, params]);

  useEffect(() => {
    async function fetchPosition() {
      const res = await fetch(`${API_URL}/activity/${params.id}`, {
        credentials: "include",
      });
      const position = await res.json()
      setPosition(position.position)
    }
    auth &&fetchPosition().then();
  }, [auth, params]);

  useEffect(() => {
    async function fetchActivities() {
      const res = await fetch(`${API_URL}/activity/all`, {
        credentials: "include",
      });
      const activities = await res.json();
      setActivities(activities)
      console.log('activities', activities)
    }

    fetchActivities().then();
  }, [position]);

  return (
      <div className="Container">
        <World config={config} position={position.coord}
               land={land}
               fetching={fetching} computeAlpha={computeAlpha}/>
        <Info 
              activities={activities}/>
      </div>
  );
}

export default VisitContainer;
