import {Box, Grid, Paper, TextField} from "@mui/material";
import AccountCircle from '@mui/icons-material/AccountCircle';

import './Welcome.css';
import {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import {useAuth} from "./auth/UseAuth";

const API_URL = process.env.REACT_APP_API_URL

function WelcomePage() {

  const auth = useAuth();
  const [pois, setPois] = useState([]);
  const [pseudo, setPseudo] = useState('');
  const [error, setError] = useState(false);
  const [position, setPosition] = useState();

  useEffect(() => {
    async function fetchPoi() {
      const res = await fetch(`${API_URL}/poi`, {
        credentials: "include",
      });
      const poi = await res.json();
      setPois(poi.Items)
    }

    fetchPoi().then();
  }, []);

  const pseudoValid = (pseudo) => pseudo.trim().length !== 0

  const onClick = (poi) => {
    if (pseudoValid(pseudo)) {
      async function postMe() {
        const requestOptions = {
          method: 'POST',
          credentials: "include",
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({name: pseudo})
        };
        await fetch(`${API_URL}/me`, requestOptions)
      }

      async function fetchMove() {
        const requestOptions = {
          method: 'POST',
          credentials: "include",
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({coord: poi.coord})
        };
        const response = await fetch(`${API_URL}/position`, requestOptions)
        const newPosition = await response.json()
        await auth.signin();
        setPosition(newPosition)
      }

      postMe().then(fetchMove)
    } else {
      setError(true)
    }
  }

  const handleChange = (event) => {
    const newPseudo = event.target.value;
    setPseudo(newPseudo)
    setError(pseudoValid(pseudo))
  }

  return (<div className="Welcome main">
    {position && <Navigate to={'/world'} replace/>}
    Pour commencer,
    <div>
      Choisissez un pseudo
      <Box className={'form'}>
        <Paper className={'input'}>
          <AccountCircle sx={{color: 'action.active', mr: 1, my: 0.5}}/>
          <TextField label="Pseudo" variant="standard"
                     value={pseudo}
                     onChange={handleChange} required error={error}/>
        </Paper>
      </Box>
    </div>
    <div>
      et une zone de depart
    </div>
    <Grid container justifyContent=" center" className={'form'}>{pois
        && pois.map(
            poi => <Paper className={'poi'} key={poi.name}
                          onClick={() => onClick(poi)}
                          sx={{
                            textAlign: 'center',
                            maxWidth: 275,
                            minWidth: 275
                          }}>{poi.name}</Paper>)}</Grid>
  </div>);
}

export default WelcomePage;
