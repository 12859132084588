import { useEffect, useState } from "react";
import "./Arrow.css";

const directionToConfig = {
  N: { r: 180, position: { x: 0, y: 1 }, shadow: { x: 10, y: 0 } },
  S: { r: 0, position: { x: 0, y: -1 }, shadow: { x: 10, y: 0 } },
  E: { r: -90, position: { x: 1, y: 0 }, shadow: { x: 10, y: 0 } },
  W: { r: 90, position: { x: -1, y: 0 }, shadow: { x: 10, y: 0 } },
};

function Arrow({ config, direction, onClick }) {
  const [center_x, setCenter_x] = useState();
  const [center_y, setCenter_y] = useState();
  const [squareSize, setSquareSize] = useState(1);
  const [scale, setScale] = useState(0);

  useEffect(() => {
    const { size, vision } = config;
    setSquareSize(size.width / (vision * 2 + 1));
  }, [config]);

  useEffect(() => {
    setScale(0.4);
  }, [squareSize]);

  useEffect(() => {
    const { size } = config;
    setCenter_x(
      size.width / 2 + directionToConfig[direction].position.x * 40
    );
    setCenter_y(
      size.height / 2 - directionToConfig[direction].position.y * 40
    );
  }, [squareSize, config, direction]);

  return (
    <>
      {center_x && (
        <g>
          <g
            id="arrow"
            onClick={() =>
              onClick(() => ({direction}))
            }
            className={"arrow"}
            transform={` translate(${center_x}, ${center_y}) scale(${
              scale * 0.5
            }, ${scale * 0.5}) rotate(${directionToConfig[direction].r})`}
          >
            <g transform={` translate(0 -30)`}>
              <g transform={` translate(-10 0)`} className="main">
                <polygon points="-5,-50 25,-50 25,0 50,0 10,50 -30,0, -5,0" />
              </g>
              <g className="light">
                {direction === "N" && (
                  <line x1={-11} y1={-45} x2={11} y2={-45} />
                )}

                {direction === "N" && <line x1={-32} y1={6} x2={-16} y2={6} />}
                {direction === "N" && <line x1={14} y1={6} x2={30} y2={6} />}

                {direction === "E" && (
                  <line x1={-11} y1={-45} x2={-11} y2={0} />
                )}
                {direction === "W" && <line x1={11} y1={-45} x2={11} y2={0} />}

                {(direction === "S" || direction === "E") && (
                  <line x1={-32} y1={6} x2={0} y2={43} />
                )}
                {(direction === "S" || direction === "W") && (
                  <line x1={32} y1={6} x2={0} y2={43} />
                )}
              </g>
            </g>
          </g>
        </g>
      )}
    </>
  );
}

export default Arrow;
