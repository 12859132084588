import {useEffect, useState} from "react";
import World from "../svg/World";

const logo = [
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 1, 1, 1, 0, 1, 1, 1, 0, 1, 1, 1, 0, 1, 1, 1, 0, 0, 0],
  [0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1, 0, 0],
  [0, 0, 0, 1, 1, 0, 0, 1, 1, 1, 0, 1, 1, 1, 0, 1, 0, 0, 1, 0, 0],
  [0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1, 0, 0],
  [0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0, 1, 1, 1, 0, 1, 1, 1, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
]

const logoLand = logo.flatMap(
    (valeurs, ligne) => valeurs.map(
        (valeur, colonne) => ({
          coord: {x: colonne, y: -ligne},
          type: valeur ? 'GRASS' : 'DESERT'
        })))
console.log(logoLand)
const config = {
  size: {width: 350, height: 150}, vision: 10, me: {
    radius: 4,
  },
};

function WorldContainer() {
  const [position, setPosition] = useState({});
  const [move, setMove] = useState();
  const [land, setLand] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [activity, setActivity] = useState();

  const computeAlpha = (me, position) => {
    const {
      coord: {x, y},
    } = me;
    const show = me.visible || (x === position.x && y === position.y);
    return show ? 1 : 1 - (0.4 + (Math.abs(x - position.x) + Math.abs(
        y - position.y)) / 24);
  };

  useEffect(() => {
    setPosition({coord: {x: 10, y: -4}})
    setLand(logoLand)
  }, []);

  return (
      <div className="Container">
        <World config={config} position={position.coord} setMove={setMove}
               land={land}
               fetching={fetching} seed={activity} computeAlpha={computeAlpha}/>

      </div>
  );
}

export default WorldContainer;
