import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/UseAuth";

function Activities({activities}) {

  let navigate = useNavigate();
  const auth = useAuth();
  const [userId, setUserId] = useState()

  useEffect(() => {
    setUserId(auth.user.id)
  }, [auth]);

  function handleClick(id) {
    const link = id === userId?'/world':`/visit/${id}`
    navigate(link);
  }

  return (
      <div className="Activities">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {activities.map((row) => (

                  <TableRow
                  onClick={() =>handleClick(row.userId)}
                      key={row.name}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.activity?row.activity.total:0}</TableCell>
                  </TableRow>

              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
  );
}

export default Activities;
