import { useEffect, useRef, useState } from "react";
import "./Tile.css";

function CityName({ config, me, position, alpha }) {
  const [center_x, setCenter_x] = useState();
  const [center_y, setCenter_y] = useState();
  const [squareSize, setSquareSize] = useState(1);
  const textEl = useRef(null);
  const [BBox, setBBox] = useState();

  useEffect(() => {
    const { size, vision } = config;
    setSquareSize(size.width / (vision * 2 + 1));
  }, [me, config]);

  useEffect(() => {
    const {
      coord: { x, y },
    } = me;
    const { size } = config;
    setCenter_x(size.width / 2 + (x - position.x) * squareSize);
    setCenter_y(size.height / 2 - (y - position.y) * squareSize);
    textEl?.current?.getBBox && setBBox(textEl.current.getBBox())
  }, [position, me, squareSize, config]);

  return (
    <>
      {center_x && center_y &&(
        <g>
          <g className={"CityName"}  transform={` translate(0 -7)`}>
            {me.type === "CITY" && (
              <g>
              {BBox && <rect x={BBox.x-5} y={BBox.y+10} height={BBox.height-20} width={BBox.width+10} className={"back"} rx={4}/>}
              <text
              ref={textEl}
                x={center_x}
                y={center_y}
                textAnchor={"middle"}
                fontSize="30"
              >
                {me.name}
              </text>
       
            </g>
            )}
          </g>

        </g>
      )}
    </>
  );
}

export default CityName;
