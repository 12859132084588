import { Navigate } from "react-router-dom";
import { useAuth } from "./UseAuth";

function RequireAuth({ children }) {
    let auth = useAuth();
  
    if (!auth.user) {
      return <Navigate to="/"  replace />;
    }
  
    return children;
  }

  export default RequireAuth