import Arrow from "./Arrow";
import "./Arrow.css";

function Arrows({ config, onClick,className }) {
  return (
    <g style={{ filter: "url(#dropshadow)" }} className={`arrows ${className}`}>
      <Arrow config={config} direction={"N"} onClick={onClick}/>
      <Arrow config={config} direction={"S"} onClick={onClick}/>
      <Arrow config={config} direction={"W"} onClick={onClick}/>
      <Arrow config={config} direction={"E"} onClick={onClick}/>
    </g>
  );
}

export default Arrows;
