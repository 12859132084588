import React from "react";
import Arrows from "./Arrows";
import CityName from "./CityName";
import Me from "./Me";
import TilePlacement from "./TilePlacement";
import "./World.css";
import Seed from "./Seed";
import Tile from "./Tile";

function World({config, position, land, fetching, setMove, seed, computeAlpha}) {



  const tileMapping = (tile, config) => {
    const alpha = computeAlpha(tile, position);
    return (alpha < 0 ? <React.Fragment key={tile.tileId}/> : <g
        key={tile.tileId}>
      <TilePlacement
          config={config}
          me={tile}
          position={position}
          alpha={alpha}
      />
    </g>)
  };
  return (<svg width={config.size.width} height={config.size.height}
               className="world">
        {position ? <g className="global">
          {land && land.map((tile) => tileMapping(tile, config))}
          <defs>
            <style type="text/css">@import
              url('https://fonts.googleapis.com/css2?family=Dongle&display=swap');
            </style>
            <Tile key="grass" config={config} type={'grass'}/>
            <Tile key="desert" config={config} type={'desert'}/>
            <Tile key="city" config={config} type={'city'}/>
            <Tile key="poi" config={config} type={'poi'}/>
          </defs>
          <filter id="dropshadow" height="130%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
            <feOffset dx="2" dy="2" result="offsetblur"/>
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.5"/>
            </feComponentTransfer>
            <feMerge>
              <feMergeNode/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <Me config={config}/>
          {land.filter(tile => tile.type === "CITY").map(
              (tile) => (<g key={"name" + tile.tileId}>
                    <CityName
                        config={config}
                        me={tile}
                        position={position}
                        alpha={computeAlpha(tile, position)}
                    />
                  </g>))}

          {seed && seed.current && <Arrows key="arrows" config={config}
                                           onClick={setMove}
                                           className={fetching ? 'fetching'
                                               : ''}/>}
          {seed && <Seed key={"seed"} config={config} seed={seed}/>}
        </g> : <></>}
      </svg>);
}

export default World;
