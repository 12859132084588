import gFit from "./gfit_512dp.png";
import {Button} from "@mui/material";
import "./LoginPage.css";
import Container from "../welcome/Container";

const API_URL = process.env.REACT_APP_API_URL

function LoginPage() {
  return (
      <div className={"LoginPage"}>
        <Container/>
        <div>Seed permet de suivre votre activité en plantant un lopin de gazon
          pour chaque kilometre parcouru.
        </div>
        <a href={`${API_URL}/login`}>
          <Button variant="contained">
            <img src={gFit} alt="Google Fit logo"/>Synchronisation Google Fit
          </Button>
        </a>
      </div>
  );
}

export default LoginPage;
